// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';


// Bootstrap
@import '~bootstrap/scss/bootstrap';

//Main

body{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #707070;
    padding-bottom: 50px;//chevron-up
}

.me-logo {
    color: #DB505A;
}

.me-bg-pink {
    background: #fdbabf;
}

.me-color-grey {
    color: #707070;
}

.me-input-text {
    background: #fdbabf;
}


.me-input-text:focus {
    background: #fdbabf;
}

.me-bg-red {
    background: #DB505A;
}

.me-bg-grey {
    background: #f3f3f3;
}

.me-color-white {
    color:white;
}

.me-color-white:hover {
    color:white;
}
.me-color-black {
    color:black;
}

.me-color-black:hover {
    color:black;
}

.text-bold{
    font-weight: 600;
}

.me-link-red {
    color: #DB505A;
}

.me-link-red:hover{
    color: #DB505A;
}
/*
.table{
    font-size: 0.75em;
}*/

tbody {
    font-size: 0.9em;
}
thead {
    font-size: 0.8em;
}

.me-bagde {
    font-weight: 500;
    border-radius: 5px;
}

.me-size-9 {
    font-size: 0.9em;
}

.me-btn-head-table-left {
    width: 120px;
}


/*
.me-fixed{
    position:fixed;
}


.me-fixed + .content {
    padding-top: 60px;
}
.content {
    z-index: -1;
}*/

body {margin:0;}

.navbar {
  //overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;

}


.content {
    z-index: 1;

  padding: 16px;
  margin-top: 55px;
}

.side-bar {
    height: 100%;
    //width: 215px;
    //width: 50px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    padding-top: 16px;
  }

.side-bar-lg {
    width: 215px;
}

.side-bar-min {
    width: 50px;
}

  .me-breadcrumb-item {
      font-size: 1.5 em;
      font-weight: 500;
      color: #707070;
  }

  .second-nav {
      border-bottom: solid 1.5px #707070;
  }


.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}


.me-card {
    /* Add shadows to create the "card" effect */
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      transition: 0.3s;
    }
.me-shadow-btn {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
}

    
  .me-li {
    color: #707070;
  }

  .me-li:hover {
    background:#eaecef;
  }

  
  .me-li:focus {
    border: #f4f5f7;
  }

  .me-li.active {
    color: blue;
    background:#eaecef;
  }

  
  .me-li:hover.active {
    color: #475772;
    background:#eaecef;
  }

  .me-side-bar-item {
    font-size: 0.9em;
  }

         
  #myBtn {
   display: none;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    font-size: 18px;
    border: none;
    outline: none;
    background-color: #DB505A;
    color: white;
    cursor: pointer;
    padding: 5px;
    border-radius: 4px;
  }
  
  #myBtn:hover {
    background-color: #707070;
  }